import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import { Heading } from '../../components';
import { types as sdkTypes } from '../../util/sdkLoader';

import css from './ListingPage.module.css';

const { Money } = sdkTypes;

const SectionMatesRates = props => {
  const { publicData, intl } = props;
  const packages = publicData?.packages || [];

  if (packages.length === 0) {
    return null;
  }

  return (
    <div className={css.sectionMatesRates}>
      <Heading as="h2" rootClassName={css.sectionHeading}>
        <FormattedMessage id="ListingPage.matesRatesTitle" />
      </Heading>
      <ul className={css.matesrates}>
        {packages.map((item, index) => {
          const price = formatMoney(intl, new Money(item.price.amount, item.price.currency));
          return (
            <li key={`package-${index}`} className={css.matesratesRow}>
              <div className={css.matesratesItem}>
                <div className={css.matesrateLabel}>{item.title}</div>
                <div className={css.matesrateDescription}>{item.description}</div>
                <div className={css.matesratePrice}>
                  <span>{item.turnaroundTime}</span>
                  <span className={css.matesratePriceTotal}>{price}</span>
                </div>
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default SectionMatesRates;
