import React from 'react';
import { CSVLink } from 'react-csv';

import { FormattedMessage } from '../../util/reactIntl';

import { AvatarSmall, H4, IconSpinner, Modal, NamedLink } from '../../components';

import css from './ListingPage.module.css';

const GuestsModal = ({
  guests,
  listingSlug,
  selectedTimeslot,
  onClose,
  onManageDisableScrolling,
}) => {
  const { guests: participants = [], fetchGuestsError = null, fetchGuestsInProgress = null } =
    guests[selectedTimeslot] || {};

  const csvHeaders = [
    { label: 'NAME(s)', key: 'guestNames' },
    { label: 'PHOTOPASS', key: 'photopassRequirements' },
    { label: 'ADVANCED COVERAGE', key: 'coverageContent' },
    { label: 'CONTACT EMAIL', key: 'contactEmail' },
    { label: 'ADDITIONAL REQUESTS', key: 'additionalRequests' },
  ];

  const csvData = participants.map(user => {
    const {
      additionalRequests,
      contactEmail,
      coverageContent,
      guestNames,
      photopassRequirements,
    } = user.guestData;

    return {
      additionalRequests,
      contactEmail,
      coverageContent,
      guestNames,
      photopassRequirements,
    };
  });

  const filename = `${selectedTimeslot}_${listingSlug}.csv`;

  return (
    <Modal
      id="GuestsModal"
      contentClassName={css.guestListModalContent}
      isOpen={!!selectedTimeslot}
      onClose={onClose}
      usePortal
      onManageDisableScrolling={onManageDisableScrolling}
    >
      <div className={css.guestsList}>
        <H4>
          <FormattedMessage id="GuestsModal.guestListTitle" />
        </H4>

        {fetchGuestsError ? (
          <FormattedMessage id="GuestsModal.failedToFetchGuests" />
        ) : fetchGuestsInProgress ? (
          <IconSpinner />
        ) : participants.length ? (
          <>
            <CSVLink data={csvData} filename={filename} headers={csvHeaders} separator=";">
              <FormattedMessage id="GuestsModal.downloadCsv" />
            </CSVLink>
            {participants.map(user => (
              <div className={css.guest} key={user.id.uuid}>
                <AvatarSmall className={css.guestAvatar} user={user} />
                <NamedLink
                  className={css.link}
                  name="ProfilePage"
                  params={{ id: user.id.uuid }}
                  target="_blank"
                  rel="noreferrer"
                >
                  {user.attributes.profile.displayName}
                </NamedLink>
              </div>
            ))}
          </>
        ) : null}
      </div>
    </Modal>
  );
};

export default GuestsModal;
